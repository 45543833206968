import { getToken } from "@/util/auth";
import { portalV1URL } from "@/env";

export default {
  // 个人中心左边枚举
  centerList: [
    {
      code: "a",
      title: "个人信息",
      icon: require("./../../public/imgs/center-img/icon-info.png"),
      iconActive: require("./../../public/imgs/center-img/icon-info-active.png"),
    },
    {
      code: "b",
      title: "我的课程",
      icon: require("./../../public/imgs/center-img/my_course.png"),
      iconActive: require("./../../public/imgs/center-img/my_course_active.png"),
    },
    // {
    //   code: 'c',
    //   title: '我的项目',
    //   icon: require("./../../public/imgs/center-img/icon-info.png"),
    //   iconActive: require("./../../public/imgs/center-img/icon-info-active.png")
    // },
    {
      code: "d",
      title: "我的竞赛",
      icon: require("./../../public/imgs/center-img/my_complete.png"),
      iconActive: require("./../../public/imgs/center-img/my_complete_active.png"),
    },
    {
      code: "e",
      title: "我的会议",
      icon: require("./../../public/imgs/center-img/my_meeting.png"),
      iconActive: require("./../../public/imgs/center-img/my_meeting_active.png"),
    },
    {
      code: "f",
      title: "收藏夹",
      icon: require("./../../public/imgs/center-img/my_collect.png"),
      iconActive: require("./../../public/imgs/center-img/my_collect_active.png"),
    },
    {
      code: "g",
      title: "学习成果",
      icon: require("./../../public/imgs/center-img/my_study.png"),
      iconActive: require("./../../public/imgs/center-img/my_study_active.png"),
    },
      // 新增挑战题库
    {
      code: "j",
      title: "挑战题库",
      icon: require("../../public/imgs/center-img/my_question.png"),
      iconActive: require("../../public/imgs/center-img/my_question_active.png"),
    },
    {
      code: "h",
      title: "考勤记录",
      icon: require("./../../public/imgs/center-img/my_clock.png"),
      iconActive: require("./../../public/imgs/center-img/my_clock_active.png"),
    },
    {
      code: 'i',
      title: '智能助教',
      icon: require("./../../public/imgs/center-img/my_meeting.png"),
      iconActive: require("./../../public/imgs/center-img/my_meeting_active.png"),
    },
  ],
  bannerList: {
    homeBannerList: [
      {
        id: 0,
        url: require("../assets/img/banner.png"),
      },
    ],
    eduBannerList: [
      {
        id: 0,
        url: require("../assets/img/edu_banner.png"),
      },
    ],
    workBannerList: [
      {
        id: 0,
        url: require("../assets/img/work_banner.png"),
      },
    ],
    fieldBannerList: [
      {
        id: 0,
        url: require("../assets/img/field_banner.png"),
      },
    ],
    codeBannerList: [
      {
        id: 0,
        url: require("../assets/img/code_banner.png"),
      },
    ],
  },
  introductions: [
    {
      title: "水杉课堂",
      subtitle: "开放式全民计算机科学教育课堂",
      content: [
        "超强师资团队",
        "超酷实训体验",
        "贴心学习服务",
        "让学习无处不在！",
      ],
      isFirst: true
    },
    {
      title: "水杉工坊",
      subtitle: "交互式在线实训服务的极速体验",
      content: ["交互式实训", "极速体验", "大数据实训", "AI实训"],
      isFirst: false
    },
    {
      title: "水杉校场",
      subtitle: "个性化在线编辑学习的自动评测",
      content: ["个性化测试", "自适应学习", "精准定位", "自我提升"],
      isFirst: false
    },
    {
      title: "水杉码园",
      subtitle: "智能代码托管与项目协作平台",
      content: ["课程作业", "课外写作", "激励机制", "数据分析"],
      isFirst: false
    },
  ],
  reasons: [
    {
      img: require("../assets/img/why_choose-knowledge.png"),
      title: "新工科知识",
      keyWords: "大数据、云计算、人工智能、区块链、虚拟现实技术等",
    },
    {
      img: require("../assets/img/why_choose-edu.png"),
      title: "智慧+教育",
      keyWords: "开放、共享、交互、协作、数字化、网络化、智能化、多媒体化",
    },
    {
      img: require("../assets/img/why_choose_K12.png"),
      title: "K12教育",
      keyWords: "智慧基础教育能力",
    },
    {
      img: require("../assets/img/why_choose-business.png"),
      title: "商务智能",
      keyWords: "现代数据仓库技术、线上分析处理技术、数据挖掘和数据展现技术",
    },
  ],
  // 测验的选择
  questionJson: [
    {
      choice: "A",
      currentChoice: "choice1",
    },
    {
      choice: "B",
      currentChoice: "choice2",
    },
    {
      choice: "C",
      currentChoice: "choice3",
    },
    {
      choice: "D",
      currentChoice: "choice4",
    },
  ],
  // 课程的种类
  courseType: [
    {
      dictValue: "1",
      type_zh: "作业",
      dictLabel: "work",
    },
    {
      dictValue: "2",
      type_zh: "视频",
      dictLabel: "video",
    },
    {
      dictValue: "3",
      type_zh: "课件",
      dictLabel: "file",
    },
    {
      dictValue: "4",
      type_zh: "测验",
      dictLabel: "test",
    },
    {
      dictValue: "5",
      type_zh: "实训",
      dictLabel: "train",
    },
    {
      dictValue: "6",
      type_zh: "其他",
      dictLabel: "other",
    },
    {
      dictValue: "7",
      type_zh: "编程",
      dictLabel: "code",
    },
    {
      dictValue: "8",
      type_zh: "代码仓库",
      dictLabel: "warehouse",
    },
    {
      dictValue: "9",
      type_zh: "课评",
      dictLabel: "assess",
    },
  ],
  // 课程看板的课程资源
  courseResources: [
    {
      dictValue: "homeworkResNums",
      dictLabel: "作业",
    },
    {
      dictValue: "videoResNums",
      dictLabel: "视频",
    },
    {
      dictValue: "coursewareResNums",
      dictLabel: "课件",
    },
    {
      dictValue: "testResNums",
      dictLabel: "测试",
    },
    {
      dictValue: "practiceResNums",
      dictLabel: "实训",
    },
    {
      dictValue: "programResNum",
      dictLabel: "编程",
    },
    {
      dictValue: "otherResNums",
      dictLabel: "其他",
    },
  ],
  // 课程看板的资源使用情况
  resourceConditions: [
    {
      dictLabel: "视频观看",
      dictValue: "videoTimes",
    },
    {
      dictLabel: "测试完成",
      dictValue: "examTimes",
    },
    {
      dictLabel: "作业完成",
      dictValue: "homeworkTimes",
    },
  ],
  // 考试成绩对比
  examScore: [
    {
      dictLabel: "最高分",
      dictValue: "maxScore",
    },
    {
      dictLabel: "最低分",
      dictValue: "minScore",
    },
    {
      dictLabel: "平均分",
      dictValue: "avgScore",
    },
    {
      dictLabel: "个人成绩",
      dictValue: "totalScore",
    },
  ],
  // 月份
  months: [
    {
      dictLabel: "1月",
      dictValue: 1,
    },
    {
      dictLabel: "2月",
      dictValue: 2,
    },
    {
      dictLabel: "3月",
      dictValue: 3,
    },
    {
      dictLabel: "4月",
      dictValue: 4,
    },
    {
      dictLabel: "5月",
      dictValue: 5,
    },
    {
      dictLabel: "6月",
      dictValue: 6,
    },
    {
      dictLabel: "7月",
      dictValue: 7,
    },
    {
      dictLabel: "8月",
      dictValue: 8,
    },
    {
      dictLabel: "9月",
      dictValue: 9,
    },
    {
      dictLabel: "10月",
      dictValue: 10,
    },
    {
      dictLabel: "11月",
      dictValue: 11,
    },
    {
      dictLabel: "12月",
      dictValue: 12,
    },
  ],
  // 课程标签的种类
  courseTagTypes: [
    {
      dictLabel: "专业必修课",
      dictValue: 1,
      course: [],
    },
    {
      dictLabel: "公共必修课",
      dictValue: 2,
      course: [],
    },
    {
      dictLabel: "特色课程",
      dictValue: 3,
      course: [],
    },
    {
      dictLabel: "图书配套课程",
      dictValue: 4,
      course: [],
    },
  ],
  studentRankList: [
    require("@/assets/img/course/first.png"),
    require("@/assets/img/course/second.png"),
    require("@/assets/img/course/third.png"),
  ],
  workShopLinkList: [
    {
      name: "水杉实训",
      url: "",
      image: require("@/assets/img/question/shuishan_logo.png"),
      description: "交互式实训、大数据实训、AI实训，极速体验，无需安装本地环境，快速进行程序练习，大幅提升学习效率！"
    },
    {
      name: "VS Code",
      url: "http://139.224.55.101:8259/login",
      image: require("@/assets/img/question/training_platform.png"),
      description: "Visual Studio Code 是 Microsoft 开发的运行于 Mac OS X、Windows和 Linux 之上的，针对于编写现代Web和云应用的跨平台源代码编辑器"
    },
    {
      name: "Jupyterhub",
      url: "https://jupyterhub.shuishan.net.cn/hub/login?next=%2Fhub%2F",
      image: require("@/assets/img/question/jupyterhub.png"),
      description: "支持多用户的 Jupyter Notebook 服务器，用于创建、管理、代理多个 Jupyter Notebook 实例，具有扩展性和可定制性。"
    },
    {
      name: "无影云桌面（仅校内）",
      url: "https://www.shuishan.net.cn/wuyingDialog",
      image: require("@/assets/img/question/desktop.png"),
      description: "无影云桌面 (Elastic Desktop Service），是一种易用、安全、高效的云上桌面服务。它支持快速便捷的桌面环境创建、部署、统一管控与运维。"
    },
  ],
  fieldLinkList: [
    {
      name: "OnlineJudge",
      description: "Online Judge 是一个在线判题系统。用户可以在线提交程序源代码，系统编译和执行，并通过测试数据来检验正确性。",
      url: "https://judgefield.shuishan.net.cn/oauth?access_token=" + getToken(),
      image: require("@/assets/img/question/online_judge.png"),
      rankList:[
        {
          title:"求指定区间的奇数个数",
          num:1245
        },
        {
          title:"求指定区间的和",
          num:1183
        },
        {
          title:"约数倍数选卡片",
          num:873
        },
        {
          title:"字符串转换成十进制整数",
          num:790
        },
        {
          title:"A+B Problem",
          num:542
        }
      ]
    },
    {
      name: "Python校场",
      description: "Python校场 是一个在线判题系统。用户可以在线提交程序源代码，系统编译和执行，并通过测试数据来检验正确性。",
      url: portalV1URL + "/oauth",
      redirect: "pythonPractice",

      image: require("@/assets/img/question/pythonOJ.png"),
      rankList:[
        {
          title:"求指定区间的奇数个数-test数据",
          num:1245,
          id: "1434404448235397121"
        },
        {
          title:"求指定区间的和",
          num:1183,
          id: "1434404448235397121"
        },
        {
          title:"约数倍数选卡片",
          num:873
        },
        {
          title:"字符串转换成十进制整数",
          num:790
        },
        {
          title:"A+B Problem",
          num:542
        }
      ]
    },
    {
      name: "SQL校场",
      description: "SQL校场 是一个在线SQL语句训练系统。用户可以在线执行SQL语句并查看执行结果，并提供错误反馈来总结经验。",
      url: portalV1URL + "/oauth",
      // 本地调试
      // url: "http://localhost:9557/oauth",
      redirect: "sqlPractice",

      image: require("@/assets/img/question/SQLOJ.png"),
      rankList:[
        {
          title:"求指定区间的奇数个数-test数据",
          num:1245,
          id: "1434404448235397121"
        },
        {
          title:"求指定区间的和",
          num:1183,
          id: "1434404448235397121"
        },
        {
          title:"约数倍数选卡片",
          num:873
        },
        {
          title:"字符串转换成十进制整数",
          num:790
        },
        {
          title:"A+B Problem",
          num:542
        }
      ]
    },
    {
      name: "天梯挑战",
      description: "天梯挑战是人工智能和机器学习课程专用的教学平台，具有作业提交、学员管理、自动批改等功能。",
      url: "https://mladder.shuishan.net.cn/my/",
      image: require("@/assets/img/question/challenge.png"),
      rankList:[
        {
          title:"Numpy教程",
          num:1245
        },
        {
          title:"人工智能基础与应用A-作业1",
          num:1183
        },
        {
          title:"计算机视觉与多媒体信息处理",
          num:873
        },
        {
          title:"2022高校学生人工智能训练营",
          num:790
        },
        {
          title:"人工智能初探-大作业",
          num:542
        }
      ]
    },
    // {
    //   name: "挑战题库",
    //   description: "挑战题库可提供学生自主的自动化评测，及时对学生填入的答案结果进行判断，并给予针对性的知识和教学。",
    //   url: "",
    //   image: require("@/assets/img/question/question.png"),
    //   rankList:[
    //     {
    //       title:"Tableau 数据分析与可视化综合",
    //       num:1245
    //     },
    //     {
    //       title:"计算机系统硬件",
    //       num:1183
    //     },
    //     {
    //       title:".NET 生态",
    //       num:873
    //     },
    //     {
    //       title:"数字视频",
    //       num:790
    //     },
    //     {
    //       title:"信息系统与数字社会概述",
    //       num:542
    //     }
    //   ]
    // },
    // {
    //   name: "竞赛",
    //   description: "竞赛板块用来查看、报名和参与学院举办的各项编程竞赛，比赛后，参赛者可以通过此模块回顾比赛，查看自己获得的证书。",
    //   url: "",
    //   image: require("@/assets/img/question/competition.png"),
    //   rankList:[
    //     {
    //       title:"程序设计基础12月第一场闵行B场",
    //       num:1245
    //     },
    //     {
    //       title:"理科组等级考试8点开始（中北校区）",
    //       num:1183
    //     },
    //     {
    //       title:"程序设计基础12月第一场闵行C场",
    //       num:873
    //     },
    //     {
    //       title:"程序设计基础12月第一场中北B场",
    //       num:790
    //     },
    //     {
    //       title:"计算机视觉与多媒体信息处理12月第二场",
    //       num:542
    //     }
    //   ]
    // },
  ],

  codeParkList: [
    // {
    //   name: "Gitea",
    //   description: "开源社区驱动的轻量级代码托管解决方案",
    //   url: "https://gitea.shuishan.net.cn/ ",
    //   image: require("@/assets/img/question/GITEE.png"),
    //   hotList: [
    //     {
    //       title:"10195501438/Operating_System",
    //       num:43204
    //     },
    //     {
    //       title:"CSAPP.Qianweining.2020Fall.DasE/lab",
    //       num:39847
    //     },
    //     {
    //       title:"13204402429/CloudComputing FinalProject",
    //       num:34053
    //     },
    //     {
    //       title:"10185501402/Chatbot4MedicalAdvices",
    //       num:12405
    //     },
    //     {
    //       title:"Internet.Liuyao.2020Fall.All/AllStu",
    //       num:9877
    //     }
    //   ],
    //   recommendList: [
    //     {
    //       title:"10205501458/Heartbeating_emotion_recog…",
    //       num:854
    //     },
    //     {
    //       title:"Al-B.Liuyao.2022Spring.Shenghuan / AlISt…",
    //       num:784
    //     },
    //     {
    //       title:"Contemporary_DMS.ZHOU_Xuan.2021Fall…",
    //       num:764
    //     },
    //     {
    //       title:"10205501451/Lazy_Timer",
    //       num:643
    //     },
    //     {
    //       title:"52215903010/DasE-Cloud",
    //       num:598
    //     }
    //   ]
    // },
    // {
    //   name: "GitHub",
    //   description: "面向开源及私有软件项目的托管平台",
    //   url: "https://github.com/ECNUdase",
    //   image: require("@/assets/img/question/GitHub.png"),
    //   hotList: [
    //     {
    //       title:"ECNU-ICA/ECNU_graduation_thesis_templ…",
    //       num:43204
    //     },
    //     {
    //       title:"ECNU-ICA/ICA-Paper-Sharing-2021Spring",
    //       num:39847
    //     },
    //     {
    //       title:"ECNU-ICA /seminar",
    //       num:34053
    //     },
    //     {
    //       title:"ECNU-ICA/ICA-Paper-Sharing-2020Fall",
    //       num:12405
    //     },
    //     {
    //       title:"ECNU-ICA / ECNU-SenseMaker",
    //       num:9877
    //     }
    //   ],
    //   recommendList: [
    //     {
    //       title:"ZhangZihao270/DaSE_DBMS_Implemention",
    //       num:854
    //     },
    //     {
    //       title:"Koyamin/ECNUThesis-Undergraduate",
    //       num:784
    //     },
    //     {
    //       title:"johnnychen94/ecnu-net-login",
    //       num:764
    //     },
    //     {
    //       title:"itaowei/Simply-SW-programming-languag…",
    //       num:643
    //     },
    //     {
    //       title:"eveJiang/DaSE-Summer-Project",
    //       num:598
    //     }
    //   ]
    // },
    {
      name: "GitLab",
      description: "一体化DevOps平台",
      url: "https://jihulab.com/cs4all/course/programming?",
      image: require("@/assets/img/question/Gitlab.png"),
      hotList: [
        {
          title:"myzmh/ECNU-MASTER-S-DISSERTATION-",
          num:43204
        },
        {
          title:"eveJiang/IDaSE-Summer-Prgject",
          num:39847
        },
        {
          title:"jaychenle/ecnu-course-timetable",
          num:34053
        },
        {
          title:"xioccMo/ExerciseBigDataSystem",
          num:12405
        },
        {
          title:"Ghostlikei/Crawler_ECNU",
          num:9877
        }
      ],
      recommendList: [
        {
          title:"salutonly/ECNU-Template",
          num:854
        },
        {
          title:"telescopeuser/Fundamentals-of-Artificial-…",
          num:784
        },
        {
          title:"Chains-Z/bus_system-vue",
          num:764
        },
        {
          title:"Haskely/ECNUMasterThesisLatexTemplate",
          num:643
        },
        {
          title:"Asami2d1/ECNU-algorithms-in-data-think…",
          num:598
        }
      ]
    },
  ],

  // 首页合作伙伴的图标
  companyList: [
    require("@/assets/img/index/huadong_school_library_LOGO.png"),
    require("@/assets/img/index/inter_LOGO.png"),
    require("@/assets/img/index/huadong_school_edu_LOGO.png"),
    require("@/assets/img/index/guizhou_school_LOGO.png"),
    require("@/assets/img/index/computed_info_safe_school_LOGO.png"),
    require("@/assets/img/index/beijing_info_school_LOGO.png"),
    require("@/assets/img/index/zhongruan_national_LOGO.png"),
    require("@/assets/img/index/sanya_school_LOGO.png"),
    require("@/assets/img/index/nanjing_teacher_school_LOGO.png"),
    require("@/assets/img/index/info_science_school_LOGO.png"),
    require("@/assets/img/index/shanghai_qianyuqian_LOGO.png"),
    require("@/assets/img/index/huadong_school_LOGO.png"),
    require("@/assets/img/index/huadong_school_english_LOGO.png"),
    require("@/assets/img/index/chinese_police_school_LOGO.png"),
    require("@/assets/img/index/huadong_school_chinese_LOGO.png"),
    require("@/assets/img/index/west_south_school_LOGO.png"),
  ],
  // 底部footer水杉服务的列表
  serverList: [
    {
      dictLabel: "水杉管理员看板",
      dictValue:
        "https://datav.aliyuncs.com/share/b67eef09d362373297fbe3c1c542d6ba",
    },
    {
      dictLabel: "新生第一课看板",
      dictValue:
        "https://datav.aliyuncs.com/share/3c1710696a7b921bcdef904f5866c75c",
    },
    {
      dictLabel: "新生计算机第一课",
      dictValue: "https://www.shuishan.net.cn/cs101",
    },
    {
      dictLabel: "教师后台",
      dictValue: "https://teacher.shuishan.net.cn/",
    },
    {
      dictLabel: "水杉反馈",
      dictValue: "imgs/QQgroup.png",
    },
  ],
  // 底部footer赞助单位的图片列表
  donateList: [
    require("@/assets/img/index/donate_edu.png"),
    require("@/assets/img/index/donate_huawei.png"),
    require("@/assets/img/index/donate_qq.png"),
    require("@/assets/img/index/donate_ucloud.png"),
  ],
  //课程合作高校
  cooperationSchoolList: [
    require("@/assets/img/school/high_school1.png"),
    require("@/assets/img/school/high_school2.png"),
    require("@/assets/img/school/high_school3.png"),
    require("@/assets/img/school/high_school4.png"),
    require("@/assets/img/school/high_school5.png"),
  ],

  // 开源教育 介绍详情
  programIntro: [
    {
      icons: [
        require("@/assets/img/program/inter_logo.png"),
        require("@/assets/img/program/inter_logo2.png"),
      ],
      title_zh: "英特尔® 中国高校人工智能人才国际培养计划",
      title_en: "AI For Future Workforce",
      introduction: ` <p style="text-indent: 2em;">英特尔®AI For Workforce 项目是一个面向(未来)从业人员的全球AI技能培训项目，旨在为人工智能发展储备应用型人才。作为英特尔全球承诺的一部分，到2030年，英特尔将把数字化准备(Digital Readiness)工作扩展到30个国家、3万个机构，培训3000万人，使他们胜任当前和未来的工作岗位。</p>
      <p style="text-indent: 2em;">目标：帮助未来的从业人员人员获得必要的人工智能技能，以提高其在数字经济中的就业能力。解决人工智能带来的技术危机，以满足日益增长的与AI/ML相关的工作需求。对AI从业人员培养产生积极的行业/社会影响力。展示强有力的技能成果，包括技术技能、职业发展技能和社会技能。</p>`,
      coverUrl: require("@/assets/img/program/inter_video_cover.png"),
      video:
        "https://shuishan-video.oss-cn-shanghai.aliyuncs.com/Shuishan_Intel.mp4",
    },
    {
      icons: [
        require("@/assets/img/program/data_school_logo.png"),
        require("@/assets/img/program/data_school_logo2.png"),
        require("@/assets/img/program/data_school_logo3.png"),
      ],
      title_zh: "数据科学与工程学院 • 专业核心课程",
      title_en: "CS101 Powered by Shuishan",
      introduction: ` <p>肩负着“探索和传播‘数据’知识”的使命，本着“开放、融合、创新、践行”的发展理念，数据科学与工程学院自 2017 年开始招收数据专业本科生以来，一方面明确数据专业内涵，构建专业知识体系，另一方面通过多种手段，为学生营造良好的学习和实验环境，鼓励创新实践活动。学院联合国内众多知名高校，广泛吸收学界和业界专家的建议，构建了国内一流的本科培养体系。其中的核心课程融入了最前沿的知识和最新的教学理念，旨在帮助学生掌握数据专业的思维模式和职业素养。 同时，学院以数据驱动的计算教育学为核心理念，自主研发并构建了规模化可扩展的个性化终身学习平台"水杉在线"，并且和众多企业合作打造平台的生态和内容，以数据要素创新驱动激发教育潜能，助力实现"有教无类、因材施教、寓教于乐、教学相长"的中国教育智慧。 请查看学院官网：<a style='color: rgba(255,255,255,0.51);cursor: pointer;'>http://dase.ecnu.edu.cn/ </a> </p>`,
      coverUrl: require("@/assets/img/program/data_school_cover.png"),
      video:
        "https://xlab-video.oss-cn-shanghai.aliyuncs.com/xlab/CS101Video.mp4",
    },
    {
      icons: [require("@/assets/img/program/computer_logo.png")],
      title_zh: "新生计算机第一课",
      title_en: "CS101 Powered by Shuishan",
      introduction: ` <p  style="text-indent: 2em;"> 大家好，欢迎来到华东师范大学“新生计算机第一课”。 培养具有数字素养、数据意识、编程思维、数字化学习与创作、数字社会责任的数字公民成为大学计算机基础教育的重要任务。 “新生计算机第一课”就是在大学计算机公共课与数字化人才培养的语境下，通过技术与教育的深度融合，为规模化个性化的分层人才培养模式的做好准备，为实现 “有教无类、因材施教、寓教于乐、教学相长”的中国教育智慧做好铺垫，是华东师范大学新一轮基础教育改革的重要组成部分之一。 在各位同学正式进入大学校门之前，我们通过在线学习的形式，为大家准备了一门入门课程、一个学习平台、以及一份评测证书，帮助大家熟悉数字化时代的学习方式。</p>`,
      coverUrl: require("@/assets/img/program/computer_video_cover.png"),
      video:
        "https://xlab-video.oss-cn-shanghai.aliyuncs.com/xlab/CS101Video.mp4",
    },
  ],
  // inter学院卡片
  collegeList: [
    {
      id: 1,
      title: "活动详情与报名",
      introduction:
        "活动详情具体内容活动详情具体内容活动详情具体内容活动详情具体内容。",
      status: 0,
    },
    {
      id: 2,
      title: "人工智能初探",
      introduction:
        "人工智能主要是通过算法，使计算机模拟人类的智慧，解决一些问题。",
      status: 1,
    },
    {
      id: 3,
      title: "OceanBase学院",
      introduction:
        "主要包括个人信息、课程、会议、竞赛、学习成果、考勤记录等情况。",
      status: 1,
    },
  ],

  // 问题反馈的 问题分类
  questionType: [
    {
      dictLabel: "登录注册",
      dictValue: "0",
      children: ["登录问题", "忘记密码", "注册问题"],
    },
    {
      dictLabel: "水杉学堂",
      dictValue: "1",
      children: ["视频播放", "课件问题", "作业问题"],
    },
    {
      dictLabel: "水杉工坊",
      dictValue: "2",
      children: ["服务器问题", "运行问题", "其他问题"],
    },
    {
      dictLabel: "水杉校场",
      dictValue: "3",
      children: ["校场登录问题", "代码提交问题", "其他问题"],
    },
    {
      dictLabel: "其他问题或建议",
      dictValue: "4",
      children: null,
    },
  ],
  //假数据
  studentList: [
    {
      studentName: "王刚",
      loginTimes: 999,
    },
    {
      studentName: "张三",
      loginTimes: 888,
    },
    {
      studentName: "李四",
      loginTimes: 666,
    },
    {
      studentName: "陈小米",
      loginTimes: 555,
    },
    {
      studentName: "王小明",
      loginTimes: 333,
    },
  ],
  //码园的公告栏
  codeNewList: [
    {
      title: "当代数据库管理系统课程实验二仓库开启",
      time: "2023-01-15 17:30",
      url: require("@/assets/img/codePark/new_first.png"),
    },
    {
      title: "水杉码园新版系统正式上线！",
      time: "2023-01-16 13:30",
      url: require("@/assets/img/codePark/new_second.png"),
    },
    {
      title: "人工智能基础与应用作业提交正在进行",
      time: "2023-01-1 17:30",
      url: require("@/assets/img/codePark/new_third.png"),
    },
    // {
    //   title: "数据科学与工程导论年度大作业提交",
    //   time: "2023-01-15 17:30",
    //   url: require("@/assets/img/codePark/new_forth.png"),
    // },
    // {
    //   title: "无线网与互联网应用课程报名",
    //   time: "2023-01-15 17:30",
    //   url: require("@/assets/img/codePark/new_fifth.png"),
    // },
  ],
  //码园热度
  codeHotList: [
    {
      title: "Python",
      hot: "1245k",
    },
    {
      title: "Java",
      hot: "1245k",
    },
    {
      title: "C++",
      hot: "1245k",
    },
    {
      title: "JavaScript",
      hot: "1245k",
    },
    {
      title: "C",
      hot: "1245k",
    },
    // {
    //   title: "C#",
    //   hot: "1245k",
    // },
    // {
    //   title: "Ruby",
    //   hot: "1245k",
    // },
    // {
    //   title: "PHP",
    //   hot: "1245k",
    // },
    // {
    //   title: "Objective-C",
    //   hot: "1245k",
    // },
    // {
    //   title: "BASIC",
    //   hot: "1245k",
    // },
  ],
  //通知待办事项
  todoItems: [
    {
      status: 0,
      title:
        "你参加的课程《概率论与数理统计》即将开始，请立即前往课程页面打卡。",
      time:"2023-02-12 13:25"
    },
    {
      status: 0,
      title:
        "你参加的课程《数据科学与工程数学基础》期末考试成绩已出，前往个人中心可查看成绩和证书详情。",
      time:"2023-02-08 14:30"
    },
    {
      status: 0,
      title:
        "开源教育 Intel 学院已开启2023年度报名。该项目是一个面向未来从业人员的全球 AI 技能培训项目，旨在为人工智能发展储备应用型人才。点击前往项目页面立即报名。",
      time:"2023-01-19 16:30"
    },
    {
      status: 1,
      title:
        "2023年年度编程竞赛开始报名。",
      time:"2023-01-18 12:30"
    },
    {
      status: 1,
      title:
        "选修课《多媒体技术与应用》已开始报名，点击查看详情。",
      time:"2023-01-12 10:20"
    },
    {
      status: 1,
      title:
        "新版水杉码园已上线，带来全新模块和视觉体验。",
      time:"2023-01-04 14:24"
    },
    {
      status: 1,
      title:
        "2023年度《新生第一课》已上线，点击立即报名参加。",
      time:"2022-12-24 01:50"
    },
    {
      status: 1,
      title:
        "《开源社年度报告摘要/开源社主要成就》已发布，点击查看。",
      time:"2022-12-03 08:30"
    },
    {
      status: 1,
      title:
        "水杉工坊《云计算系统：开发者的视角》已上线，本实训课程为云计算系列实训的启蒙课程，是云计算技术与应用 专业中一门综合性很强的基础课程。点击立即进入练习。",
      time:"2022-11-13 12:40"
    },
    {
      status: 1,
      title:
        "水杉工坊《云计算核心技术-Docker容器实践》已上线，Docker是一种轻量级虛拟化技术，对其学习和掌握需要具备一 定的操作系统、文件系统等相关基础，点击立即进入练习。",
      time:"2022-11-04 17:23"
    },
  ],
  //系统通知
  systemNoticeList: [
    {
      status: 0,
      title: "密码修改成功！",
      time:"2023-02-12 13:25"
    },
    {
      status: 0,
      title: "密码修改成功！",
      time:"2023-02-08 14:30"
    },
    {
      status: 0,
      title: "你已被老师划归到1402班。",
      time:"2023-01-19 16:30"
    },
    {
      status: 0,
      title: "绑定手机号修改成功！",
      time:"2023-01-18 12:30"
    },
    {
      status: 0,
      title: "密码修改成功！",
      time:"2023-01-12 10:20"
    },
    {
      status: 0,
      title: "用户名修改成功！",
      time:"2023-01-04 14:24"
    },
    {
      status: 1,
      title: "你的竞赛报名已提交成功！",
      time:"2022-12-24 01:50"
    },
    {
      status: 1,
      title: "你已成功报名《开源社年度报告摘要/开源社主要成就》。",
      time:"2022-12-03 08:30"
    },
    {
      status: 1,
      title: "你的积分排名第3，已进入前5！",
      time:"2022-11-13 12:40"
    },
    {
      status: 1,
      title: "你的代码库已提交成功！",
      time:"2022-11-04 17:23"
    },
  ]
};
