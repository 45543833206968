import Vue from 'vue'
import '@/assets/icons'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import '@/assets/scss/element-variables.scss'
import VueCookie from 'vue-cookie'
import store from './store'
import App from './App.vue'
Vue.use(Element, { zIndex: 3000 })

import { parseTime, selectDictLabel, selectDictLabels, addZero, configCountDownString } from "@/util/index"
import '@/assets/style/global.scss'

// XSS 攻击
import myxss from './util/xssWhiteList.js'
import * as echarts from 'echarts'
import '@/util/ready.js'
import eventBus from '@/util/eventBus.js'
import Driver from '@/util/driver.js'

// 引入 Quasar
import Quasar, { Notify } from 'quasar'
import 'quasar/dist/quasar.min.css'


// 注册 Quasar，并显式启用插件（如 Notify）
Vue.use(Quasar, {
    config: {}, // 可以添加全局配置
    plugins: {
        Notify, // 如果需要使用 Notify 插件
    },
})

Vue.prototype.$xss = (val) => {
    return myxss.process(val)
}
Vue.config.productionTip = false
Vue.use(VueCookie)

// 自定义全局函数
Vue.prototype.parseTime = parseTime
Vue.prototype.configCountDownString = configCountDownString
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.addZero = addZero
Vue.prototype.echarts = echarts
Vue.prototype.eventBus = eventBus
Vue.prototype.Driver = Driver

new Vue({
    store,
    router,
    el: '#app',
    render: h => h(App),
}).$mount('#app')
